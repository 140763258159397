<template>
  <Page>
    <div v-if="state.instruction">
      <Instruction @close="onAlertClose" :level="state.instruction.level">
        <p v-html="state.instruction.message"></p>
      </Instruction>
    </div>
    <div class="planContainer">
      <div class="plan" v-for="plan in state.plans" :key="plan.id">
        <div class="titleContainer" :class="{ free: plan.price === 0 }">
          <div class="title">
            {{ plan.name }}<span v-if="plan.applied">&nbsp;(契約中)</span>
          </div>
        </div>
        <div class="infoContainer">
          <div class="price">
            <p>￥{{ parseFloat(plan.price) >= 0 ? plan.price : "Ask" }}</p>
            <span>&nbsp;/&nbsp;月</span>
          </div>
          <ul class="features">
            <li>
              <strong
                >{{
                  parseFloat(plan.price) >= 0 ? plan.quota : "-"
                }}&nbsp;回</strong
              >&nbsp;/&nbsp;月
            </li>
            <li>
              <strong>10&nbsp;万文字</strong
              >
              / 回
            </li>
            <li>
              <div v-if="plan.has_annotation">
                <strong>注釈あり</strong>
              </div>
              <div v-else>注釈なし</div>
            </li>
            <li>
              <div v-if="plan.is_ocr_enabled">
                <strong>OCR&nbsp;可</strong>
              </div>
              <div v-else>OCR&nbsp;不可</div>
            </li>
            <li>
              <div v-if="plan.code == 'enterprise' || plan.code == 'standard'">
                <strong>翻訳元言語：英/独</strong>
              </div>
              <div v-else>
                翻訳元言語：英
              </div>
            </li>
            <li>
              <div v-if="plan.code == 'enterprise' || plan.code == 'standard'">
                翻訳先言語：日
              </div>
              <div v-else>
                翻訳先言語：日
              </div>
            </li>
          </ul>
          <button
            class="uk-button uk-button-default uk-button-large"
            :disabled="plan.code.startsWith('enterprise')"
            v-if="plan.applied"
            v-ga-track="{
              action:
                plan.code === 'trial' || plan.code === 'free'
                  ? 'click-withdraw-button'
                  : 'click-resign-button',
              params: { plan: plan.code },
            }"
            @click="
              plan.code === 'trial' || plan.code === 'free'
                ? onResignButtonClick(plan)
                : onCancelButtonClick(plan)
            "
          >
            {{
              plan.code === "trial" || plan.code === "free" ? "退会" : "解約"
            }}
          </button>
          <button
            class="uk-button uk-button-primary uk-button-large"
            v-else-if="plan.price < 0"
            @click="onAskButtonClick(plan)"
            v-ga-track="{ action: 'click-enterprise-plan-button' }"
          >
            問い合わせ
          </button>
          <button
            class="uk-button uk-button-primary uk-button-large"
            v-else
            @click="
              state.appliedPlan?.code !== 'standard'
              ? onApplyConfirmed(plan)
              : onApplyButtonClick(plan)
            "
            v-ga-track="{
              action: 'click-application-button',
              params: { plan: plan.code },
            }"
          >
            申込
          </button>
        </div>
      </div>
    </div>
    <div uk-modal="bg-close:false;" ref="alertModalRef">
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <div class="uk-modal-body">
          <div class="uk-alert-danger" uk-alert>
            <p v-for="(message, i) in state.alertModalInfo.messages" :key="`${i}_${message}`">
              {{ message }}
            </p>
          </div>
        </div>
        <div class="uk-modal-footer resign">
          <button
            class="uk-button uk-modal-close"
            v-ga-track="{ action: 'stop-withdrawal' }"
          >
            戻る
          </button>
          <button
            class="uk-button uk-button-danger"
            @click="state.alertModalInfo.onClick"
            v-ga-track="{ action: 'submit-withdrawal' }"
          >
            {{state.alertModalInfo.actionName}}
          </button>
        </div>
      </div>
    </div>
    <div uk-modal ref="inquiryModalRef">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <InquiryForm
          @submitted="onInquirySubmitted"
          type="AboutEnterprisePlan"
          :key="state.inquiryFormKey"
        />
      </div>
    </div>
  </Page>
</template>
<style lang="scss" scoped>
.page-container {
  margin-top: $spacing-medium;
  display: flex;
  justify-content: center;
  align-items: center;
}

$plan-padding: 1em;
$plan-margin: 1em;
$title-background: #f3f3f3;
$title-size: 1.45em;
$price-size: 1.35em;

button {
  min-width: 160px;
}

.planContainer {
  display: flex;
  // flex-wrap: wrap;
  overflow-x: auto;
}

.plan {
  flex: 0 0 auto;
  min-width: 300px;
  text-align: center;
  margin: $plan-margin;
  margin-bottom: $plan-margin;
  .titleContainer {
    padding: $plan-padding;
    .title {
      font-size: $title-size;
      font-weight: 700;
    }
    &.free {
    }
  }
  .infoContainer {
    padding: $plan-padding;
    box-sizing: border-box;
    .price {
      font-size: $price-size;
      padding: $plan-padding 0;
      font-weight: 600;
      margin-top: 0;
      display: inline-block;
      width: 80%;
      p {
        font-size: $price-size;
        display: inline-block;
        margin: 0;
      }
      span {
        font-size: $price-size * 0.75;
        display: inline-block;
      }
    }
    .features {
      list-style: none;
      padding-left: 0;
      li {
        padding: $plan-padding/2;
      }
    }
  }
}
.uk-modal-footer.resign {
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 25em) {
  .planContainer {
    margin: 0;
    .plan {
      width: 100%;
      margin: $plan-margin 0;
    }
  }
}
</style>
<script lang="ts">
import { defineComponent, onBeforeMount, reactive, ref, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { GetMyPlan, MyApi } from "../../autogen";
import Page from "../atoms/Page.vue";
import Instruction from "../atoms/Instruction.vue";
import { Instruction as InstrctionType } from "../../types";
import InquiryForm from "../molecules/InquiryForm.vue";
import UIkit from "uikit";
import Store from "../../store";
import { useGtag } from "vue-gtag-next";
type AlertModalInfo = {
  actionName: string;
  messages: string[];
  onClick: Function;
};
type State = {
  appliedPlan?: GetMyPlan;
  plans: GetMyPlan[];
  instruction?: InstrctionType;
  inquiryFormKey: number;
  alertModalInfo: AlertModalInfo;
};
export default defineComponent({
  components: { Page, Instruction, InquiryForm },
  setup() {
    const state: State = reactive({
      plans: [],
      inquiryFormKey: 0,
      alertModalInfo: {
        actionName: '',
        messages: [],
        onClick: () => {},
      }
    });
    const api = new MyApi();
    async function updatePlans(plans: GetMyPlan[]) {
      state.plans = plans;
      state.plans.sort((p1, p2) => {
        if (p1.applied) return 1;
        if (p2.applied) return -1;
        return (p1.price || 0) < (p2.price || 0)
          ? 1
          : (p1.price || 0) > (p2.price || 0)
          ? -1
          : 0;
      });
      state.appliedPlan = plans.find(p => p.applied);
    }
    const route = useRoute();
    const router = useRouter();
    const { event } = useGtag();
    onBeforeMount(async () => {
      const response = await api.getMyPlans({});
      updatePlans(response.data);
      const isPaymentSucceeded = route.query.success;
      const isPaymentCanceled = route.query.cancel;
      const paymentPlan = route.query.plan;
      if (isPaymentSucceeded) {
        event("submit-application", { plan: paymentPlan });
        state.instruction = {
          level: "info",
          message: `契約プランを変更しました`,
        };
      } else if (isPaymentCanceled) {
        event("cancel-application", { plan: paymentPlan });
      }
      router.replace({ query: undefined });
    });

    const onApplyButtonClick = async (_plan: GetMyPlan) => {
      state.alertModalInfo = {
        actionName: '申込（ダウングレード）',
        messages: [
          '◆ご注意ください◆',
          '1paperの翻訳サービスは、翻訳時にご契約中のプランで利用可能な機能（https://1paper.jp/plan/）と紐づきます。',
          '下位のプランに変更する場合、プラン変更後の翻訳結果は下位仕様の翻訳結果となります。（例：スタンダードプラン→ベーシックプラン　変更後はスキャンファイルを翻訳できない）',
          '下位プランの申込処理を実行してよろしいですか？',
        ],
        onClick: () => onApplyConfirmed(_plan),
      }
      setTimeout(() => {
        if (alertModalRef.value) {
          UIkit.modal(alertModalRef.value).show();
        }
      });
    };
    const onApplyConfirmed = async (plan: GetMyPlan) => {
      const profile = await api.getMyProfile({});
      // @ts-ignore
      // eslint-disable-next-line no-undef
      const stripe = Stripe(plan.stripe!.pk);
      await stripe.redirectToCheckout({
        mode: "subscription",
        lineItems: [{ price: plan.stripe!.payment_code, quantity: 1 }],
        successUrl: `${location.origin}/plan?success=1&plan=${plan.code}`,
        cancelUrl: `${location.origin}/plan?cancel=1&plan=${plan.code}`,
        customerEmail: profile.data.profile.email,
      });
      if (alertModalRef.value) {
        UIkit.modal(alertModalRef.value).hide();
      }
    };
    const onResignButtonClick = async (_plan: GetMyPlan) => {
      // because of v-clear-instruction
      state.alertModalInfo = {
        actionName: '解約',
        messages: [
          '◆ご注意ください◆',
          '退会した場合、残りの翻訳回数は使用できなくなります',
          '退会処理を実行してよろしいですか？',
        ],
        onClick: onResignConfirmed,
      }
      setTimeout(() => {
        if (alertModalRef.value) {
          UIkit.modal(alertModalRef.value).show();
        }
      });
    };
    const onCancelButtonClick = async (_plan: GetMyPlan) => {
      state.alertModalInfo = {
        actionName: '解約',
        messages: [
          '◆ご注意ください◆',
          '1paperの翻訳サービスは、翻訳時にご契約中のプランで利用可能な機能（https://1paper.jp/plan/）と紐づきます。',
          '下位のプランに変更する場合、プラン変更後の翻訳結果は下位仕様の翻訳結果となります。（例：スタンダードプラン→トライアルプラン　変更後は原文注釈がつかない）',
          '解約処理を実行してよろしいですか？',
        ],
        onClick: onCancelConfirmed,
      }
      setTimeout(() => {
        if (alertModalRef.value) {
          UIkit.modal(alertModalRef.value).show();
        }
      });
    };
    const onCancelConfirmed = async (_plan: GetMyPlan) => {
      if (alertModalRef.value) {
        await api.cancelMyPlan({});
        const response = await api.getMyPlans({});
        state.instruction = {
          level: "info",
          message: `契約プランを解約しました`,
        };
        updatePlans(response.data);
        UIkit.modal(alertModalRef.value).hide();
      }
    };
    const inquiryModalRef = ref<HTMLElement>();
    const onAskButtonClick = () => {
      state.inquiryFormKey = Date.now();
      UIkit.modal(inquiryModalRef.value!).show();
    };
    const onAlertClose = () => {
      state.instruction = undefined;
    };
    const onResignConfirmed = async () => {
      if (alertModalRef.value) {
        await api.cancelMyPlan({});
        await new MyApi().logout({});
        state.instruction = {
          level: "info",
          message: `退会処理を実行しました<br/>ご利用ありがとうございました`,
        };
        nextTick(() => {
          setTimeout(() => {
            Store.getInstance().removeAuth();
            router.push("/login");
          }, 5000);
        });
        UIkit.modal(alertModalRef.value).hide();
      }
    };
    const onInquirySubmitted = () => {
      if (inquiryModalRef.value) {
        UIkit.modal(inquiryModalRef.value).hide();
      }
    };
    const alertModalRef = ref<HTMLElement>();
    return {
      state,
      onAskButtonClick,
      onApplyButtonClick,
      onApplyConfirmed,
      onCancelButtonClick,
      onResignButtonClick,
      onAlertClose,
      inquiryModalRef,
      alertModalRef,
      onInquirySubmitted,
      onResignConfirmed,
    };
  },
});
</script>
